<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <!-- <h1>Cam kết bảo mật</h1> -->
       <!-- <div class="title-line"></div> -->
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/lithoimapcolor/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`Para esta política de privacidad, el usuario del servicio puede ser un cliente / consumidor, o cualquier otra persona que utilice el servicio o acceda a nuestra plataforma.

Si no está de acuerdo con esta política o cualquier parte de ella, no utilice ni acceda a ninguna parte de nuestra plataforma.

1. Recopilar información de identidad personal
Cuando utilice nuestras aplicaciones, solicitaremos explícitamente su permiso para recopilar y almacenar la información (información personal) que proporcione. Nuestro objetivo principal es brindarle una experiencia y un servicio seguros, eficientes, fluidos y personalizados. Esto nos permite brindar servicios y funciones que satisfacen sus necesidades y personalizar nuestra plataforma para que su experiencia sea más segura y fácil, y para mejorar los servicios que brindamos. Lo más importante es que recopilamos información personal de usted que creemos que es necesaria para lograr los propósitos mencionados anteriormente y brindarle servicios basados en esta información. 

Generalmente, puede navegar por sitios web o aplicaciones sin decirnos quién es y sin revelar ninguna información personal sobre usted. Sin embargo, para crear una cuenta en un sitio web o una aplicación, debe proporcionarnos cierta información básica necesaria para que le brindemos servicios personalizados. La información que recopilamos de usted incluye especialmente: a. Su nombre completo; b. Domicilio; c. Código de PIN; d. Datos familiares; e. Ingresos personales; f. Número de teléfono; g. Número de cuenta permanente; h. Género i . Estado civil j. Detalles de familia k. Información comercial y otra información compartida a través de formularios de solicitud o correos electrónicos u otros métodos.

Indicaremos los campos obligatorios y opcionales. A través de optar por no utilizar servicios o funciones específicos en la plataforma, siempre puede optar por no proporcionar su información personal. Cuando crea una cuenta gratuita en nuestra plataforma, recopilaremos otra información identificable de usted. Aunque puede navegar por ciertas partes de la plataforma sin registrarse como se describe anteriormente, ciertas actividades (como obtener préstamos de prestamistas terceros en la plataforma) requiere registro y requiere que proporcione los detalles anteriores. La plataforma debe mostrar claramente la información personal que recopila de usted, y puede optar por no proporcionar dicha información personal. Sin embargo, esto limitará los servicios que se le brindan en la plataforma. Este movimiento no es solo para evitar que los usuarios fraudulentos causen pérdidas a nuestra plataforma, sino también para permitirnos calificar su crédito, a fin de confirmar el análisis y el servicio de su préstamo.

2. Recopilar información de contacto
Como parte de nuestro proceso de préstamo, pedimos a los solicitantes de préstamos que proporcionen referencias. En este sentido, durante el envío del formulario en nuestra aplicación, recopilamos su información de contacto para detectar contactos cercanos, lo que le permite completar automáticamente los datos durante el proceso de solicitud de préstamo.

Además, recopilamos información de contacto de su dispositivo para el análisis de riesgos a través de detectar información de referencia confiable. Cuanto más creíble sea la referencia, menor será el riesgo asociado con el usuario.

3.SMS
Solo monitoreamos los SMS financieros (SMS financieros de 4 dígitos), incluido el remitente, la descripción y la fecha de envío en la bandeja de entrada del usuario, lo que nos ayuda a identificar las diversas cuentas que posee el usuario y los patrones de flujo de efectivo que posee el usuario para ayudarnos a determinar el perfil de riesgo del usuario y proporcionar un análisis crediticio adecuado para el usuario. Además, recopilamos y procesamos datos telefónicos, como registros de SMS para el análisis de riesgos y para evaluar la elegibilidad de un usuario para utilizar nuestros productos o servicios (que incluye, entre otros, la evaluación de la solvencia del usuario, la determinación de si el usuario puede pagar los productos o servicios solicitados por el usuario y / o la determinación de si el usuario es elegible para beneficios adicionales de los productos disponibles); para proporcionar servicios de préstamos a los usuarios (incluido, entre otros, el procesamiento de sus transacciones y el procesamiento de los pagos del préstamo del usuario y el cobro de los reembolsos del préstamo del usuario); verificar la identidad de los usuarios y / u otra información que nos proporcionen los usuarios; detectar, combatir y prevenir fraudes, intentos de fraude, lavado de dinero y / u otro uso ilegal de nuestros servicios; analizar el comportamiento del cliente; interactuar con agencias de referencia crediticia o de prevención de fraude; y proporcionar servicio o soporte al cliente.

Para contactarlo mediante mensajes de texto (SMS) (si corresponde) de acuerdo con los propósitos descritos en esta Política de privacidad.

Todos los datos de SMS se cifrarán y cargarán en nuestros servidores a través de la aplicación, pero filtraremos y eliminaremos los datos de SMS personales de nuestros servidores y nunca compartiremos estos datos con terceros.

4.Cámara
Permite que app obtenga permisos de cámara para capturar imágenes/videos y así agilizar el trámite de solicitud de préstamo.

5.Localización
Permite que app obtenga permisos de localización para poder brindar ofertas personalizadas.

6.Celular
Usaremos el código (IMEl) de su celular, ya quecon él podemos asegurar que otros celulares nopuedan reemplazarle al solicitar un préstamo previniendo así fraudes.

7. Finalidad de la recopilación de información
a nosotros recopila los datos personales para lograr los siguientes fines:
a. Cumplir con la relación contractual derivada de la solicitud de servicio y / o contrato.
b. De acuerdo con la normativa aplicable del servicio suscrito con a nosotros, verificar y confirmar la identidad del titular y la veracidad de la información facilitada (como su propia referencia y reconocimiento conjunto)
c. Administrar, operar y monitorear los servicios que solicites o contrates con a nosotros.
d. Analizar la información proporcionada por el propietario para determinar el nivel de riesgo, la capacidad de pago, el estado de la transacción y la evaluación de los servicios prestados de acuerdo con las leyes y regulaciones vigentes.
e. Confirmar formalmente la relación contractual entre a nosotros y el propietario.
f. Incluirlo en nuestra base de datos, directamente o mediante archivos de integración de terceros.
g. Procesar su solicitud por método electrónico o por teléfono.
h. Crear información personal como usuario en la aplicación para verificar e investigar la reputación.
i. Consultar y realizar una investigación crediticia con una empresa de información crediticia que crea adecuada.
j. Proporcionar ampliaciones o mejoras de los servicios estipulados en el contrato.
k. Notificar sobre actualizaciones de a nosotros.
l. Llevar a cabo inteligencia comercial, realizar estudios de mercado, comprender sus actividades en nuestros medios electrónicos, monitorear la calidad de nuestros medios electrónicos, evaluar y mejorar nuestros medios electrónicos.

8. Utilizar su información
Usamos la información que nos proporciona para establecer una identidad y verificarla de las siguientes maneras;

a. Monitorear, mejorar y administrar nuestro sitio web / plataforma;
b. Brindar nuestros servicios, es decir, realizar análisis crediticios para facilitarle la provisión de préstamos.
c. Diseñar y proporcionar productos y servicios personalizados proporcionados por nuestros socios financieros terceros;
d. Analizar el método de uso del sitio web, diagnosticar problemas técnicos o de servicio y mantener la seguridad;
e. Enviar notificaciones de comunicación, información sobre los productos o servicios que solicita, o procesar sus consultas y aplicaciones en el sitio web;
f. Gestionar nuestra relación con usted e informarle sobre otros productos o servicios, creemos que puede encontrar ciertos usos;
g. Realizar análisis de datos para mejorar los servicios / productos proporcionados a los usuarios;
h. Usar la información del usuario para cumplir con las leyes y regulaciones nacionales;
i. Realizar KYC para nuestros socios de préstamos terceros en función de la información compartida por los usuarios;
j. Usar la información del usuario de otras formas permitidas por la ley para permitirle obtener servicios financieros de nuestros socios de préstamos.

Usaremos y conservaremos su información durante el período necesario para brindarle servicios en el sitio web, cumplir con nuestras obligaciones legales, resolver disputas e implementar nuestros acuerdos.

9. Divulgación de su información
Solo compartiremos su información con nuestros terceros registrados (incluidos nuestros socios financieros regulados) para proporcionar servicios en el sitio web y / o proporcionar préstamos / facilitación a los usuarios.
a. Podemos divulgar y compartir su información con proveedores de servicios financieros, bancos y nuestros socios externos para promover préstamos o financiamiento, líneas de crédito o uso de productos;
b. Si estamos obligados a hacerlo para cumplir con cualquier obligación legal u orden gubernamental y / o de agencia estatutaria, o para implementar o aplicar nuestra información sin previo aviso, podemos divulgar su información sin previo aviso en el proceso de desinversión , fusión o protección de los derechos, propiedad o seguridad de nosotros, de nuestros usuarios u de otros, utilizando los términos de uso o distribución de dicha información. Esto incluye el intercambio de información con otras empresas y organizaciones para prevenir el fraude y reducir el riesgo crediticio.
c. Divulgamos información a nuestros socios de crédito y tecnología de terceros para verificaciones y análisis de crédito, como agencias de crédito o proveedores de fuentes de datos de terceros;
d. Compartiremos su información con terceros de acuerdo con un acuerdo de confidencialidad y restringiremos el uso de la información por parte de terceros solo para los fines detallados aquí. Le garantizamos que no compartiremos la divulgación no autorizada de su información con los terceros.

10. Confidencialidad de la información del usuario
La aplicación está diseñada para proteger su información personal y mantener la precisión confirmada por usted. Implementamos medidas de protección física, administrativa y técnica razonables para ayudarnos a proteger su información personal del acceso, uso y divulgación no autorizados. Por ejemplo, cuando transmitimos información tan sensible a través de Internet, la encriptamos. También exigimos a nuestros proveedores de servicios terceros registrados que protejan dicha información del acceso, uso y divulgación no autorizados.

Nuestra plataforma ha adoptado estrictas medidas de seguridad para proteger la pérdida, mal uso y alteración de la información controlada. Nos esforzamos por mantener y garantizar la seguridad de la información que nos proporciona. Usamos cifrado basado en Secure Sockets Layer (SSL) para transmitir información, de acuerdo con la ley, este es el nivel de cifrado que exige actualmente.

Integramos la seguridad de múltiples pasos dentro del producto con la tecnología más avanzada para garantizar que nuestro sistema mantenga fuertes medidas de seguridad, y el diseño general de seguridad de datos y privacidad nos permite defendernos contra problemas de bajo nivel hasta ataques complejos.

11. El derecho a la privacidad de los menores
Esta aplicación no es adecuada para los menores de 18 años o menores en su jurisdicción. No recopilamos a sabiendas información de identificación personal de niños menores de 18 años. Si es menor de edad, desinstale esta aplicación inmediatamente

12. Cambios de esta política de privacidad
Podemos revisar y modificar esta política de privacidad a nuestro exclusivo criterio de vez en cuando para asegurarnos de que sea coherente con nuestro desarrollo futuro y / o cambios en las leyes o regulaciones. Si decidimos modificar esta política de privacidad, le notificaremos dichas modificaciones a través de avisos generales publicados en la aplicación y / o sitio web, o a través de la dirección de correo electrónico que estableció en su cuenta. Usted acepta que es su responsabilidad verificar regularmente esta política de privacidad para obtener la información más reciente sobre nuestras prácticas de protección y procesamiento de datos, y es su responsabilidad continuar usando la aplicación o después de usar la aplicación o sitio web, contactarnos o usar el sitio web de servicio. Cualquier modificación de esta política de privacidad constituirá su aceptación de la modificación.

13. Confirmar y aceptar
Aceptar la política de privacidad significa que confirma que ha leído y comprendido esta política de privacidad y acepta todos sus términos. En particular, admite y acepta nuestra recopilación, uso, divulgación, almacenamiento, protección e intercambio.

14.Los Controles de su privacidad
Tiene los derechos para:
1. Acceder a sus datos personales en posesión de la Plataforma;
2. Revocar el consentimiento para el tratamiento de sus datos personales, revisar y / o suprimir los datos personales en posesión de la Plataforma salvo que la conservación de dichos datos sea requerida por las leyes y normativas, sin efectos retroactivos, en todos aquellos casos en los que dicha revocación, revisión y supresión no implican la imposibilidad de cumplir con la obligación derivada de una relación jurídica vigente entre usted y la Plataforma o cuando se trata de fines no necesarios.`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #F6F8F7;
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3700px;
  background-color: #F6F8F7;
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  margin-top: 50px;
 margin-left: 490px;
 color: #3F4842;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block;
}
.textBox{
  /* margin-top: 50px; */
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
.imgBox2{
  /* transform: scale(90%); */
  transform: scale(86%);
  margin: -270px auto 0;
  height: 4300px;
  /* box-shadow:0 3px 8px rgba(0, 0, 0, 0.25); */
}

.title-line{
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0BAF60;
  margin: 0 500px;
}
.img1{
  margin-top: 70px;
  padding: 0 200px;
}
</style>